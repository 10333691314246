@import "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800&display=swap";
:root {
  --themeColor: #bf0c24;
  --iconColor: white;
  --backgroundColor: var(--themeColor);
}
body {
  margin: auto;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  background-color: rgba(191, 12, 36, 0.7);
}
.swal2-container h1, .swal2-container h2, .swal2-container h3, .swal2-container h4, .swal2-container h5, .swal2-container p {
  line-height: 1;
  padding: 0;
  margin: 0;
}
.swal2-container .swal2-image {
  margin: 0;
}
.swal2-container .swal2-popup {
  overflow: hidden;
}
.swal2-container p {
  margin-top: 1rem;
}
.body {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  background-color: #fff;
  box-shadow: 0px 0 25px 11px var(--themeColor);
  overflow: hidden;
  transition: all 0.4s;
}
@media (min-width: 600px) {
  .body {
    margin: 15px auto;
    border-radius: 15px;
  }
}
.body #headshot {
  position: relative;
}
.body #headshot picture,
.body #headshot img {
  display: block;
  width: 100%;
}
.body #headshot .wave {
  position: absolute;
  bottom: 0;
}
.body a {
  text-decoration: none;
}
.body #info {
  padding: 0;
  margin: auto;
  width: 80%;
}
.body #info h1 {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.1;
}
.body #info .title {
  font-size: 1.25rem;
}
.body #info .company {
  font-style: italic;
  font-weight: 300;
}
.body #contact {
  padding: 0;
  margin: auto;
}
.body #contact ul {
  padding: 0 0 15px;
  margin: auto;
}
.body #contact ul li.list-item {
  width: 80%;
  display: block;
  margin: 0 auto;
}
.body #contact ul li.list-item:hover:not(.save-contact) {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.body #contact ul li.list-item a {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: auto;
  margin: 0px;
  padding: 0.5rem 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  color: black;
  text-decoration: none;
}
.body #contact ul li.list-item.save-contact {
  margin: 15px auto;
  width: 80%;
}
.body #contact ul li.list-item span.list-item-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.5em;
}
.body #contact ul li.list-item span.list-item-text span {
  display: block;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
}
.body #contact ul li.list-item span.list-item-text span.primary-text {
  margin: 0px;
  margin-top: 0;
}
.body #contact ul li.list-item span.list-item-text span.secondary-text {
  font-weight: 300;
  line-height: 1.5em;
  font-family: Roboto, sans-serif;
  margin-top: 0;
  line-height: normal;
  opacity: 0.7;
}
.body #contact ul li.list-item button,
.body #contact ul li.list-item .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  background-color: var(--themeColor);
  box-shadow: none;
  color: white;
  flex-shrink: 0;
  margin-right: 0.8125rem;
  transition-duration: 300ms;
  transition-property: background-color;
  border: 0;
}
.body #contact ul li.list-item button.circle-button,
.body #contact ul li.list-item .button.circle-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.8125rem;
}
.body #contact ul li.list-item button.contact,
.body #contact ul li.list-item .button.contact {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
}
.body #contact ul li.list-item button.contact i,
.body #contact ul li.list-item .button.contact i {
  padding: 0 15px;
}
.body #contact i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.body #contact i svg {
  fill: var(--iconColor);
  height: 1em;
  overflow: visible;
  width: 1em;
}
.body main {
  max-width: 300px;
}
/*# sourceMappingURL=index.7f041619.css.map */
