:root {
    --themeColor: #bf0c24;
    --iconColor: white;
    --backgroundColor: var(--themeColor);
}

$themeColor: var(--themeColor);

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800&display=swap');

body {
    // display:none;
    margin: auto;
    padding: 0;
    font-family: 'Nunito Sans', sans-serif;
    background-color: rgba(#bf0c24, .7);
}

.swal2-container {
    h1,h2,h3,h4,h5,p {
        line-height: 1;
        padding: 0;
        margin: 0;
    }

    .swal2-image {
        margin: 0;
    }

    .swal2-popup {
        overflow: hidden;
    }
    p{
        margin-top:1rem;
    }

}

.body {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    background-color: #fff;
    box-shadow: 0px 0 25px 11px var(--themeColor);
    // transform: scale(0.85);
    overflow: hidden;
    transition: all .4s;

    @media (min-width: 600px) {
        // display: none;
        margin: 15px auto;
        border-radius: 15px;
    }

    #headshot {
        position: relative;

        picture,
        img {
            display: block;
            width: 100%;
        }

        .wave {
            position: absolute;
            bottom: 0;
        }
    }

    a {
        text-decoration: none;
    }

    #info {
        padding: 0;
        margin: auto;
        width: 80%;

        h1 {
            font-size: 1.5rem;
            font-weight: 800;
            line-height: 1.1;
        }

        .title {
            font-size: 1.25rem;
        }

        .company {
            font-style: italic;
            font-weight: 300;
        }
    }

    #contact {
        padding: 0;
        margin: auto;

        ul {
            padding: 0 0 15px;
            margin: auto;

            li.list-item {
                width: 80%;
                display: block;
                margin: 0 auto;

                // padding:0 5%;
                &:hover:not(.save-contact) {
                    background-color: rgba(#000, .15);
                    border-radius: 10px;
                }

                a {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    height: auto;
                    margin: 0px;
                    padding: 0.5rem 0;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: flex-start;
                    overflow: hidden;
                    color: black;
                    text-decoration: none;

                }

                &.save-contact {
                    margin: 15px auto;
                    width: 80%;
                }

                span.list-item-text {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    line-height: 1.5em;

                    span {
                        display: block;
                        line-height: normal;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1.5em;

                        &.primary-text {
                            margin: 0px;
                            margin-top: 0;
                            // margin-bottom: -20px;
                        }

                        &.secondary-text {
                            font-weight: 300;
                            line-height: 1.5em;
                            font-family: Roboto, sans-serif;
                            margin-top: 0;
                            line-height: normal;
                            opacity: .7;
                        }
                    }

                }

                button,
                .button {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    align-self: flex-start;
                    background-color: var(--themeColor);
                    box-shadow: none;
                    color: rgb(255, 255, 255);
                    flex-shrink: 0;
                    margin-right: 0.8125rem;
                    transition-duration: 300ms;
                    transition-property: background-color;
                    border: 0;

                    &.circle-button {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 0.8125rem;

                    }

                    &.contact {
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px 0;
                        width: 100%;

                        i {
                            padding: 0 15px;
                        }

                        // font-size: 24px;
                    }
                }

            }
        }

        i {

            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            font-size: 24px;

            svg {
                fill: var(--iconColor);
                height: 1em;
                overflow: visible;
                width: 1em;
            }
        }
    }

    main {
        max-width: 300px;
    }
}